.bookTable table {
	font-family: Segoe UI;
	color: #fff;
}

.bookTable table {
	height: 288px;
	width: 100%;
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}
.loader-tr {
	background: none !important;
}
.loader-tr td {
	width: 100% !important;
}
.empty-message {
	display: grid !important;
}

.bookTable table thead {
	flex-basis: 48px;
	width: 100%;
	display: block;
	background-image: linear-gradient(
		180deg,
		#6a7ae9 0%,
		#4253c3 82%,
		#2335a5 100%
	);
	border-radius: 17px 17px 0 0;
}

.bookTable table tbody {
	flex-grow: 1;
	width: 100%;
	height: 192px !important;
	overflow: auto;
	position: relative;
	scroll-behavior: smooth;
	transform: 0.3s;
}

.bookTable table tbody::-webkit-scrollbar {
	display: none;
}

.bookTable table tfoot {
	flex-basis: 48px;
	width: 100%;
	display: block;
	background-image: linear-gradient(
		180deg,
		#6a7ae9 0%,
		#4253c3 82%,
		#2335a5 100%
	);
	border-radius: 0 0 17px 17px;
}

.bookTable table thead,
.bookTable table tfoot {
	display: flex;
	align-items: center;
}

.bookTable table thead tr,
.bookTable table tfoot tr {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
}

.bookTable table thead tr td {
	flex-basis: 33%;
}

.bookTable table thead tr td:nth-child(2) {
	text-align: center;
}

.bookTable table thead tr td button,
.bookTable table tfoot tr td button {
	padding: 0 !important;
	margin: 0 !important;
}

.bookTable table thead tr td button svg,
.bookTable table tfoot tr td button svg {
	color: white;
	font-size: 37px !important;
}

.bookTable table tbody tr {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	padding-bottom: 1.18%;
	padding-top: 0.5%;
}

.bookTable table tbody tr td {
	display: flex;
	align-items: baseline;
	width: 11%;
}

.bookTable table tbody tr:nth-child(odd) {
	background: #56657f;
}

.title {
	color: white;
}

.title svg {
	font-size: inherit;
	color: white;
	margin-right: 12%;
}

.bookTable tr {
	padding: 0 2%;
}

.titleOpt {
	display: flex;
	justify-content: flex-end;
	color: white;
}

.titleOpt span {
	display: flex;
	justify-content: flex-end;
	color: white;
	margin-left: 4%;
}

.titleOpt span:first-child {
	margin-left: 0;
}

.bookTable table tbody tr td:last-child {
	display: flex;
	justify-content: flex-end;
}

.titleOpt span {
	width: 50px;
	text-align: center;
	display: inline-block;
}

td.h4.Book.Title svg {
	margin-right: 5%;
	font-size: inherit;
}

.bookTable a {
	text-decoration: none;
	color: white;
}

.bookTable thead td {
	display: flex;
	align-items: center;
	margin-bottom: none !important;
}

@media only screen and (max-width: 600px) {
	.bookTable table tbody tr,
	.bookTable table thead tr {
		height: 35px !important;
	}

	.bookTable table thead,
	.bookTable table tfoot {
		flex-basis: 35px !important;
	}
}
