button.plus-btn {
	width: 46px;
	height: 46px;
	padding: 0 !important;
	margin: 0 !important;
	cursor: pointer;
}

@media (max-width: 700px) {
	button.plus-btn {
		width: 45px;
	}
}
