.drawer-sidebar {
	/* height: 100%!important; */
	display: flex !important;
	flex-basis: 29.018%;
}

.aside_content {
	flex: 1;
	height: auto;
	background-color: #374254;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.aside_bottom {
	height: 3.66%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.aside_top .top .bookImg img {
	width: 100%;
}

.aside_top .top h1 {
	text-transform: uppercase;
	color: white;
	margin: 3% 0;
}

.aside_top .middle {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10%;
}

.aside_top .middle button {
	border: none;
	outline: none;
	background: transparent;
	padding: 0 10%;
	cursor: pointer;
}

.aside_top .middle button img {
	width: 100%;
}

.aside_top .bottom h4 {
	color: white;
}

.aside_top .bottom button {
	color: white;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	padding: 0 !important;
}

.aside_middle {
	overflow-y: auto;
}

.aside_middle::-webkit-scrollbar {
	display: none;
}
