*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

a {
	all: unset;
}

.highlight {
	background-color: #ffff0054 !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
	padding: unset;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.navbar-carousel .react-multi-carousel-dot button {
	background: gray !important;
	border-color: gray !important;
}

.navbar-carousel .react-multi-carousel-dot-list {
	bottom: 15px !important;
}

.navbar-carousel .react-multi-carousel-dot--active button {
	background: #fff !important;
	border-color: #fff !important;
}
