.qnaTable table {
	font-family: Segoe UI;
	color: #fff;
}
.qnaTable table {
	height: 288px;
	width: 100%;
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.loader-tr {
	background: none !important;
}
.loader-tr td {
	width: 100%;
}
.empty-message {
	display: grid !important;
}

.qnaTable table thead {
	flex-basis: 48px;
	width: 100%;
	display: block;
	background-image: linear-gradient(
		180deg,
		#6a7ae9 0%,
		#4253c3 82%,
		#2335a5 100%
	);
	border-radius: 17px 17px 0 0;
}
.qnaTable table tbody {
	flex-grow: 1;
	width: 100%;
	height: 192px !important;
	overflow: auto;
	position: relative;
	scroll-behavior: smooth;
	transform: 0.3s;
}

.qnaTable table tbody::-webkit-scrollbar {
	display: none;
}

.qnaTable table tfoot {
	flex-basis: 48px;
	width: 100%;
	display: block;
	background-image: linear-gradient(
		180deg,
		#6a7ae9 0%,
		#4253c3 82%,
		#2335a5 100%
	);
	border-radius: 0 0 17px 17px;
}

.qnaTable table thead,
table tfoot {
	display: flex;
	align-items: center;
}
.qnaTable table thead tr,
table tfoot tr {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
}
.qnaTable table thead tr td {
	flex-basis: 15%;
	margin-right: 1%;
}
.qnaTable table thead tr td:nth-child(2) {
	text-align: center;
}
.qnaTable table thead tr td button,
.qnaTable table tfoot tr td button {
	padding: 0 !important;
	margin: 0 !important;
}
.qnaTable table thead tr td button svg,
.qnaTable table tfoot tr td button svg {
	color: white;
	font-size: 37px !important;
}

.qnaTable table tbody tr {
	/* height: 48px !important; */
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	padding-bottom: 1.18%;
	padding-top: 0.49%;
}

.qnaTable table tbody tr td {
	height: 100% !important;
	display: flex;
	align-items: center;
	margin-right: 1%;
	line-height: 0.9;
	gap: 6%;
	margin-right: 1.5%;
}
.qnaTable table tbody tr:nth-child(odd) {
	background: #56657f;
}
.title {
	color: white;
	display: flex;
	align-items: center;
}
.title svg {
	font-size: inherit;
	color: white;
	margin-right: 5%;
}

.qnaTable tr {
	padding: 0 2%;
}

.titleOpt {
	display: flex;
	justify-content: flex-end;
	color: white;
}
.titleOpt span {
	display: flex;
	justify-content: flex-end;
	color: white;
	margin-left: 4%;
}
.titleOpt span:first-child {
	margin-left: 0;
}

.qnaTable table tbody tr td:last-child {
	display: flex;
	justify-content: flex-end;
}

.titleOpt span {
	width: 30px;
	text-align: center;
	display: inline-block;
}

@media only screen and (max-width: 600px) {
	.qnaTable table thead,
	.qnaTable table tfoot {
		flex-basis: 35px !important;
	}
	.qnaTable table tbody tr {
		height: 35px !important;
	}
}
