.react-pdf__Page {
	height: auto;
	z-index: 1;
}

.react-pdf__Page__textContent {
	background: #2a333a;
}

.textLayer :is(span, br) {
	color: white;
}
